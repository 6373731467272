import { SnackbarProvider } from "notistack";
import React, { Component } from "react";
import "./App.css";
// import "./assets/Graphik-Medium.ttf";
import "./assets/Inter-VariableFont_slnt,wght.ttf";
// import "@tensorflow/tfjs-backend-webgl";
import Slide from "@material-ui/core/Slide";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import CallingComponent from "./components/CallingComponent/CallingComponent";
import Logger from "./lib/Logger";
import Theme from "./components/CallingComponent/Utils/Theme";
import { LocalizedString, videoSettings } from "./Utils/Constants";
const TAG = "CallingComponet";

const queryString = window.location.search;
const urlparams = new URLSearchParams(queryString);
var isBroadcast, isBroadcaster;
var showEndCallButton = true;
var showMuteAudioButton = true;
var showPauseVideoButton = true;
var defaultlayout = true;
var isAudioOnly = false;
var showScreenShareButton = true;
var showSwitchModeButton = true;
var showSwitchToVideoCallButton = true;
var startAudioMuted, startVideoMuted;
var region,
  sessionID,
  name,
  avatar,
  mode,
  uid,
  domain,
  analyticsSettings,
  localizedObject = LocalizedString,
  isLegacy = false,
  user,
  customCSS,
  uiVersion,
  showRecordingButton = true,
  startRecordingOnCallStart = false,
  appID,
  ShowVirtualBackgroundSetting = true,
  AllowBackgroundBlur = true,
  AllowUserImages = true,
  SetImages = [],
  ShowDefaultImages = true,
  EnforceBackgroundBlur = 0,
  EnforceBackgroundImage = "",
  avatarMode = "circle",
  screenShareMode = "default";

class App extends Component {
  constructor() {
    super();
    this.state = {
      isFullScreen: false,
      loadCallScreen: false,
    };
  }

  componentDidMount() {
    // customCSS = null;
    // const $style = document.createElement("style");
    // document.head.appendChild($style);
    // $style.innerHTML = customCSS;
    if (!urlparams.get("v")) {
      sessionID = urlparams.get("sessionID");
      name = urlparams.get("username");
      mode = urlparams.get("mode");
      region = "us";
      domain = "rtc-us.cometchat.io";
      uid = "uid";
      if (region === "pidc") {
        isLegacy = true;
      }
      startAudioMuted = true;
      startVideoMuted = true;
      uiVersion = 2;
      this.setState({ loadCallScreen: true });
      mode = "TILE";
    } else {
      this.addSettingListener();
      this.sendLoadMessage();
    }
  }

  /**
   * sendLoadMessage sends the SDK a message that the calling component is loaded succesfully
   */
  sendLoadMessage() {
    Logger.log(TAG, "Load Message sent");
    window.postMessage(
      window.parent.postMessage(
        JSON.stringify({ type: "cometchat_action_message", action: "LOAD" }),
        "*"
      ),
      "*"
    );
  }
  /**
   * addSettingListener will add a listener that will listen for the setting of the call
   */
  addSettingListener() {
    window.addEventListener("message", (message) => {
      if (message.data && message.data.type && message.data.type === "cometchat_rtc_settings") {
        if (message.data.callsettings) {
          Logger.log(TAG, "Settings received", message);
          if (region === "pidc") {
            isLegacy = true;
          }
          var settings = JSON.parse(message.data.callsettings);
          screenShareMode = settings.screenShareMode || "default";
          showEndCallButton = settings.ShowEndCallButton;
          showRecordingButton = settings.ShowRecordingButton;
          startRecordingOnCallStart = settings.StartRecordingOnCallStart;
          showMuteAudioButton = settings.ShowMuteAudioButton;
          showPauseVideoButton = settings.ShowPauseVideoButton;
          showScreenShareButton = settings.ShowScreenShareButton;
          showSwitchToVideoCallButton = settings.ShowSwitchToVideoCallButton;
          defaultlayout = settings.defaultLayout;
          sessionID = settings.sessionID;
          region = settings.region.toLowerCase();
          isAudioOnly = settings.isAudioOnly;
          name = settings.user.name;
          avatar = settings.user.avatar;
          mode = settings.mode;
          uid = settings.user.uid;
          if (typeof settings.localizedObject === "object")
            localizedObject = {
              ...LocalizedString,
              ...settings.localizedObject,
            };
          startAudioMuted = settings.StartAudioMuted;
          startVideoMuted = settings.StartVideoMuted;
          customCSS = settings.customCSS;
          user = settings.user;
          appID = settings.appId;
          analyticsSettings = settings.analyticsSettings;
          showSwitchModeButton = settings.ShowSwitchModeButton;
          ShowVirtualBackgroundSetting = settings.ShowVirtualBackgroundSetting;
          AllowBackgroundBlur = Boolean(settings.VirtualBackground?.AllowBackgroundBlur);
          AllowUserImages = Boolean(settings.VirtualBackground?.AllowUserImages);
          ShowDefaultImages = Boolean(settings.VirtualBackground?.ShowDefaultImages);
          SetImages = settings.VirtualBackground?.SetImages
            ? settings.VirtualBackground?.SetImages
            : [];
          EnforceBackgroundBlur = settings.VirtualBackground?.EnforceBackgroundBlur
            ? settings.VirtualBackground?.EnforceBackgroundBlur
            : 0;

          EnforceBackgroundImage = settings.VirtualBackground?.EnforceBackgroundImage
            ? settings.VirtualBackground?.EnforceBackgroundImage
            : "";
          avatarMode = settings.AvatarMode ? settings.AvatarMode : "circle";

          if ("MainVideoContainerSetting" in settings) {
            if (typeof settings.MainVideoContainerSetting?.videoFit === "string") {
              videoSettings.fit = settings.MainVideoContainerSetting.videoFit;
            }

            if (typeof settings.MainVideoContainerSetting?.zoomButton?.visibility === "boolean") {
              videoSettings.fitButton.visible =
                settings.MainVideoContainerSetting.zoomButton.visibility;
            } else if (
              typeof settings.MainVideoContainerSetting?.zoomButton?.visible === "boolean"
            ) {
              videoSettings.fitButton.visible =
                settings.MainVideoContainerSetting.zoomButton.visible;
            }

            if (typeof settings.MainVideoContainerSetting?.zoomButton?.position === "string") {
              videoSettings.fitButton.position =
                settings.MainVideoContainerSetting.zoomButton.position;
            }

            if (typeof settings.MainVideoContainerSetting?.nameLabel?.visibility === "boolean") {
              videoSettings.nameLabel.visible =
                settings.MainVideoContainerSetting.nameLabel.visibility;
            } else if (
              typeof settings.MainVideoContainerSetting?.nameLabel?.visible === "boolean"
            ) {
              videoSettings.nameLabel.visible =
                settings.MainVideoContainerSetting.nameLabel.visible;
            }

            if (typeof settings.MainVideoContainerSetting?.nameLabel?.position === "string") {
              videoSettings.nameLabel.position =
                settings.MainVideoContainerSetting.nameLabel.position;
            }

            if (typeof settings.MainVideoContainerSetting?.nameLabel?.color === "string") {
              videoSettings.nameLabel.background =
                settings.MainVideoContainerSetting.nameLabel.color;
            }

            if (typeof settings.MainVideoContainerSetting?.network?.visibility === "boolean") {
              videoSettings.network.visible = settings.MainVideoContainerSetting.network.visibility;
            } else if (typeof settings.MainVideoContainerSetting?.network?.visible === "boolean") {
              videoSettings.network.visible = settings.MainVideoContainerSetting.network.visible;
            }

            if (typeof settings.MainVideoContainerSetting?.network?.position === "string") {
              videoSettings.network.position = settings.MainVideoContainerSetting.network.position;
            }
          }

          uiVersion = 1;
          if (urlparams.get("v") == 2) {
            domain = "rtc-" + region + ".cometchat.io";
          } else {
            domain = settings.domain;
          }
          if (domain.includes("rtc-staging.cometchat.io")) {
            domain = "rtc-us.cometchat-staging.com";
          }

          if (urlparams.get("v") > 3) {
            uiVersion = 2;
          }
          if (settings.useLegacyUI) {
            uiVersion = 1;
          }
          this.setState({ loadCallScreen: true });
          const $style = document.createElement("style");
          document.head.appendChild($style);
          $style.innerHTML = customCSS;
        }
      }
    });
  }

  render() {
    if (urlparams.get("broadcast") === 1) {
      isBroadcast = true;
    } else {
      isBroadcast = false;
    }
    const defaultTheme = {
      palette: {
        primaryIconColor: {
          main: "#1E79E7",
        },
        secondaryIconColor: {
          main: "#808080",
        },
        fontHeadingColor: {
          main: "#292A2c",
        },
        fontTitleColor: {
          main: "#292A2c",
        },
        fontSubTitleColor: {
          main: "#808080",
        },
        fontTextColor: {
          main: "#808080",
        },
        buttonBackgroundColor: {
          main: "#35383b",
        },
        error: {
          main: "#d93438",
        },
        primary: {
          main: "#1E79E7",
        },
      },
      typography: {
        fontFamily: "Inter",
        h3: {
          fontFamily: "Inter",
          fontSize: "2em",
          fontWeight: 900,
        },
        h4: {
          fontFamily: "Inter",
          fontSize: "1.5em",
          fontWeight: 700,
        },
        h5: {
          fontFamily: "Inter",
          fontSize: "1em",
          fontWeight: 400,
        },
        h6: {
          fontFamily: "Inter",
          fontSize: "0.7em",
          fontWeight: 400,
        },
      },
      spacing: (factor) => `${1 * factor}vh`,
      margin: (factor) => `${4 * factor}px`,
    };
    const theme = createTheme(defaultTheme);
    Theme.setTheme(defaultTheme);
    return (
      <ThemeProvider theme={theme}>
        <div className='App'>
          <SnackbarProvider
            autoHideDuration={6000}
            maxSnack={3}
            dense={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            TransitionComponent={Slide}
            classes={{
              containerRoot: "cc-snackbar-root-override",
            }}
          >
            {this.state.loadCallScreen ? (
              // <Fullscreen
              //   enabled={this.state.isFullScreen}
              //   onChange={(isFullScreen) => this.setState({ isFullScreen })}
              // >
              <div
                className='App-header'
                id='fullscreen'
                onDoubleClick={(e) => {
                  if (e.target.tagName === "BODY" || e.target.tagName === "DIV") {
                    this.setState({
                      isFullScreen: !this.state.isFullScreen,
                    });
                  }
                }}
              >
                <CallingComponent
                  urlVersion={urlparams.get("v")}
                  screenShareMode={screenShareMode}
                  sessionID={sessionID}
                  username={name}
                  avatar={avatar}
                  region={region}
                  isBroadcast={isBroadcast}
                  isBroadcaster={isBroadcaster}
                  mode={mode}
                  uid={uid}
                  domain={domain}
                  isAudioOnly={isAudioOnly}
                  showEndCallButton={showEndCallButton}
                  showMuteAudioButton={showMuteAudioButton}
                  showPauseVideoButton={showPauseVideoButton}
                  showScreenShareButton={showScreenShareButton}
                  defaultLayout={defaultlayout}
                  isLegacyServer={isLegacy}
                  localizedObject={localizedObject}
                  startAudioMuted={startAudioMuted}
                  startVideoMuted={startVideoMuted}
                  user={user}
                  appID={appID}
                  analyticsSettings={analyticsSettings}
                  showSwitchModeButton={showSwitchModeButton}
                  SetImages={SetImages}
                  uiVersion={uiVersion}
                  showRecordingButton={showRecordingButton}
                  startRecordingOnCallStart={startRecordingOnCallStart}
                  AllowBackgroundBlur={AllowBackgroundBlur}
                  AllowUserImages={AllowUserImages}
                  ShowDefaultImages={ShowDefaultImages}
                  EnforceBackgroundImage={EnforceBackgroundImage}
                  EnforceBackgroundBlur={EnforceBackgroundBlur}
                  ShowVirtualBackgroundSetting={ShowVirtualBackgroundSetting}
                  showSwitchToVideoCallButton={showSwitchToVideoCallButton}
                  avatarMode={avatarMode}
                />
              </div>
            ) : // </Fullscreen>
            null}
          </SnackbarProvider>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
